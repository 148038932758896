import React, { useState, useMemo, useEffect } from "react";
import {
  Card,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
  CardImg,
  Input,
} from "reactstrap";
import classnames from "classnames";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import { ethers } from 'ethers';

import MatiButton from "../Pages/Profile/Settings/MatiButton";

const Trading = ({
  coins,
  contas,
  loading,
  formatCurrency,
  saldos,
  setLoading,
  setFetch,
  tog_center,
  blockedTime,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [valorPix, setValorPix] = useState(null);
  const [qrData, setQrData] = useState(
    "https://api.fort.exchange/image/qrcode_place.jpg"
  );
  const [contaSaida, setContaSaida] = useState(0);
  const [contaDestino, setContaDestino] = useState(0);
  const [transferencia, setTransferencia] = useState(null);
  const [compraDiaria, setCompraDiaria] = useState(null);
  const [contaNome, setContaNome] = useState("");
  const [contaMoeda, setContaMoeda] = useState("");
  const [contaRede, setContaRede] = useState("");
  const [contaCarteira, setContaCarteira] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const clienteData = JSON.parse(sessionStorage.getItem("authUser"));

  const generateQRCode = async () => {
    setIsLoading(true);
    const api = axios.create();

    api.interceptors.request.use(
      (req) => {
        return req;
      },
      (error) => {
        console.log("Request Error", error);
        return Promise.reject(error);
      }
    );

    api.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        console.log("Response Error:", error.response);
        return Promise.reject(error);
      }
    );

    try {
      const response = await api({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/gerarqrcode`,
        headers: {
          Authorization: `Bearer ${clienteData.token}`,
        },
        data: {
          amount: valorPix,
        },
      });

      if (response.data.status === 1) {
        toast.success(response.data.mensagem);
        setValorPix(null);
        setQrData(response.data.data.qrcode);
      } else {
        toast.error(response.data.mensagem);
        console.log(response.data);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log("Error", error.response || error);
    }

    setIsLoading(false);
  };

  const filteredCoins = useMemo(() => {
    return coins.filter((coin) => coin.cripto_td === 1);
  }, [coins]);

  const handleCoinChange = (e) => {
    const selectedCoin = coins.find((coin) => coin.moeda === e.target.value);
    if (selectedCoin) {
      setContaMoeda(selectedCoin.moeda);
      setContaRede(selectedCoin.rede);
    }
  };

  const transferir = async () => {
    setIsLoading(true);
    try {
      const clienteData = JSON.parse(sessionStorage.getItem("authUser"));
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/cripto-td/contas/transfer`,
        headers: {
          Authorization: `Bearer ${clienteData.token}`,
        },
        data: {
          to: contaDestino,
          from: contaSaida,
          valor: parseFloat(transferencia),
        },
      });

      if (response.status === 1) {
        toast.success(response.mensagem);
        setTransferencia(null);
        setLoading(true);
        setFetch(true);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setIsLoading(false);
  };

  const criarConta = async () => {
    setIsLoading(true);
    if (contaMoeda === "ETH" || contaMoeda === "MATIC") tog_center();
    try {
      const clienteData = JSON.parse(sessionStorage.getItem("authUser"));
      
      // Format carteira as Ethereum address if not BTC or SOL
      let formattedCarteira = contaCarteira;
      if (contaMoeda !== "BTC" && contaMoeda !== "SOL") {
        try {
          formattedCarteira = ethers.utils.getAddress(contaCarteira);
        } catch (error) {
          toast.error("Endereço de carteira inválido");
          setIsLoading(false);
          return;
        }
      }

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/cripto-td/contas`,
        headers: {
          Authorization: `Bearer ${clienteData.token}`,
        },
        data: {
          user_id: clienteData.data._id,
          nome: contaNome,
          moeda: contaMoeda,
          rede: contaRede,
          carteira: formattedCarteira,
          compra_diaria: parseFloat(compraDiaria),
        },
      });

      if (response.status === 1) {
        toast.success(response.mensagem);
        // Reset all input fields
        setCompraDiaria(null);
        setContaNome("");
        setContaMoeda("");
        setContaRede("");
        setContaCarteira("");
        setLoading(true);
        setFetch(true);
      } else {
        toast.error(response.mensagem);
        console.log(response);
      }
    } catch (error) {
      toast.error("Erro na API");
      console.log(error);
    }
    setIsLoading(false);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (contas.length > 0) {
      setContaDestino(contas[0].id);
    }
  }, [contas]);

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardHeader className="align-items-center border-0 d-flex">
            <div className="flex-shrink-0">
              <Nav
                className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    PIX
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Transferir
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Criar Conta
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </CardHeader>
          <div className="card-body p-0">
            {loading || isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "20px",
                }}
              >
                <Spinner height={100} width={100} />
              </div>
            ) : (
              <TabContent activeTab={activeTab} className="p-0">
                <TabPane tabId="1">
                  {qrData !==
                  "https://api.fort.exchange/image/qrcode_place.jpg" ? (
                    <>
                      <div
                        className="p-3 bg-soft-warning"
                        style={{ textAlign: "center" }}
                      >
                        <QRCode value={qrData} size={256} />
                        <small className="text-muted mt-3">
                          <i>QR Code de duração de 1 hora</i>
                        </small>
                      </div>
                      <div
                        className="p-3"
                        style={{ textAlign: "center" }}
                      >
                        <small className="text-muted mt-3">
                          <i>QR Code de duração de 1 hora</i>
                        </small>
                      </div>
                      <div className="p-3">
                        <div className="mt-3 pt-2">
                          <CopyToClipboard text={qrData}>
                            <button
                              type="button"
                              className="btn btn-success w-100"
                              onClick={() =>
                                toast.success(
                                  "Copiado para área de transferência!"
                                )
                              }
                            >
                              <i className="bx bx-copy bx-xs"></i>
                              Pix Copia e Cola
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="p-3 bg-soft-warning"
                        style={{ textAlign: "center" }}
                      >
                        <CardImg src={qrData} style={{ width: 256 }} />
                      </div>
                      <div className="p-3">
                        {parseInt(clienteData.data.clientData.nivel_kyc) !==
                        0 ? (
                          <>
                            <div>
                              <div className="input-group mb-3">
                                <label className="input-group-text">
                                  Valor
                                </label>
                                <NumericFormat
                                  className="form-control"
                                  placeholder="0,00"
                                  value={valorPix}
                                  onValueChange={({ value }) =>
                                    setValorPix(parseFloat(value))
                                  }
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  allowNegative={false}
                                />
                                <label className="input-group-text">R$</label>
                              </div>
                            </div>
                            <div className="mt-3 pt-2">
                              <button
                                type="button"
                                className="btn btn-success w-100"
                                disabled={isLoading}
                                onClick={generateQRCode}
                              >
                                Gerar QR Code
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <div className="input-group mb-3">
                                <label className="input-group-text">
                                  Valor
                                </label>
                                <NumericFormat
                                  className="form-control"
                                  placeholder="0,00"
                                  value={valorPix}
                                  onValueChange={({ value }) =>
                                    setValorPix(parseFloat(value))
                                  }
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  allowNegative={false}
                                />
                                <label className="input-group-text">R$</label>
                              </div>
                            </div>
                            {clienteData.data.clientData.url_metamap === "" ? (
                              <div className="mt-3 pt-2">
                                <MatiButton email={clienteData.data.email} />
                              </div>
                            ) : (
                              <div className="mt-3 pt-2">
                                <a
                                  href="/profile"
                                  target="_self"
                                  className="btn btn-warning w-100"
                                >
                                  Ver Perfil
                                </a>
                              </div>
                            )}
                          </>
                        )}
                        <div className="mt-3 pt-2">
                          <a
                            href="https://api.whatsapp.com/send?phone=5519998168872&text=Ol%C3%A1%2C%20eu%20gostaria%20de%20saber%20mais%20sobre%20o%20Bitcoin%20Todo%20Dia"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="btn btn-primary w-100"
                          >
                            <i className="bx bx-chat bx-xs"></i> Fale Conosco
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                </TabPane>

                <TabPane tabId="2">
                  {!blockedTime ? (
                    <div className="p-3">
                      <Row>
                        <Col xs={6}>
                          <div className="mb-3">
                            <label>Conta Saída :</label>
                            <select
                              className="form-select"
                              value={contaSaida}
                              onChange={(e) =>
                                setContaSaida(parseInt(e.target.value))
                              }
                            >
                              {contaDestino !== 0 && (
                                <option value="0">
                                  Principal ({formatCurrency(saldos[0].counter)}
                                  )
                                </option>
                              )}
                              {contas
                                .filter(
                                  (conta) =>
                                    conta.id.toString() !== contaDestino
                                )
                                .map((conta) => (
                                  <option value={conta.id} key={conta.id}>
                                    {conta.nome} (
                                    {formatCurrency(conta.saldo_brl)})
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="mb-3">
                            <label> Conta Destino :</label>
                            <select
                              className="form-select"
                              value={contaDestino}
                              onChange={(e) =>
                                setContaDestino(parseInt(e.target.value))
                              }
                            >
                              {contaSaida !== 0 && (
                                <option value="0">
                                  Principal ({formatCurrency(saldos[0].counter)}
                                  )
                                </option>
                              )}
                              {contas
                                .filter(
                                  (conta) => conta.id.toString() !== contaSaida
                                )
                                .map((conta) => (
                                  <option value={conta.id} key={conta.id}>
                                    {conta.nome} ( (
                                    {formatCurrency(conta.saldo_brl)})
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <div className="input-group mb-3">
                          <label className="input-group-text">Valor</label>
                          <NumericFormat
                            className="form-control"
                            placeholder="0,00"
                            value={transferencia}
                            onValueChange={({ value }) =>
                              setTransferencia(parseFloat(value))
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                          />
                          <label className="input-group-text">R$</label>
                        </div>
                      </div>
                      <div className="mt-3 pt-2">
                        <button
                          type="button"
                          className="btn btn-success w-100"
                          disabled={isLoading || contaSaida === contaDestino}
                          onClick={transferir}
                        >
                          Transferir
                        </button>
                        {contaSaida === contaDestino && (
                          <span className="text-muted">
                            Conta de Saída e Destino devem ser diferentes.
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="p-3 text-center">
                      Não são permitidas transações entre 9:45 e 10:15, devido
                      ao horário da compra diária.
                    </div>
                  )}
                </TabPane>

                <TabPane tabId="3">
                  {!blockedTime ? (
                    <div className="p-3">
                      <Row>
                        <Col xs={12}>
                          <div className="mb-3">
                            <label>Moeda :</label>
                            <Input
                              type="select"
                              className="form-select"
                              value={contaMoeda}
                              onChange={handleCoinChange}
                            >
                              <option value="">Escolha uma Moeda</option>
                              {filteredCoins.map(({ id, moeda, rede }) => (
                                <option value={moeda} key={id}>
                                  {moeda} ({rede})
                                </option>
                              ))}
                            </Input>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <div className="input-group mb-3">
                          <label className="input-group-text">
                            Nome da Conta
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Conta Principal"
                            value={contaNome}
                            onChange={(e) => setContaNome(e.target.value)}
                          />
                        </div>

                        <div className="input-group mb-3">
                          <label className="input-group-text">
                            Compra Diária
                          </label>
                          <NumericFormat
                            className="form-control"
                            placeholder="0,00"
                            value={compraDiaria}
                            onValueChange={({ value }) =>
                              setCompraDiaria(parseFloat(value))
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={0}
                            suffix=",00"
                            fixedDecimalScale={true}
                            allowNegative={false}
                          />
                          <label className="input-group-text">R$</label>
                        </div>

                        <div className="input-group mb-0">
                          <label className="input-group-text">Carteira</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              contaMoeda === "BTC"
                                ? "bc1..., 3..., 1..."
                                : contaMoeda === "SOL"
                                ? "A3z..."
                                : "0x..."
                            }
                            value={contaCarteira}
                            onChange={(e) => setContaCarteira(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="mt-3 pt-2">
                        <button
                          type="button"
                          className="btn btn-primary w-100"
                          onClick={criarConta} // Adicionado o manipulador de eventos ao clicar
                        >
                          Criar Conta
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="p-3 text-center">
                      Não são permitidas transações entre 9:45 e 10:15, devido
                      ao horário da compra diária.
                    </div>
                  )}
                </TabPane>
              </TabContent>
            )}
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Trading;
